import React from 'react';
import Stamp from './components/Stamp';
import {
    MypageWrapper
        } from './styled';

const Mypage = () => {
    return (
        <>
            <MypageWrapper>
                
            </MypageWrapper>
        </>
    );
};

export default Mypage;