import styled from 'styled-components';

export const ListWrapper = styled.div`
    overflow-y: scroll;
    height: 400px;
`;

export const List = styled.div`
    text-align: left;
    padding: 0px 20px;
    strong{
        font-size: 22px;
    }
    p{
        font-size: 14px;
        line-height: 1.4;
    }
`;

export const ListsWrapper = styled.div`

`;

export const Lists = styled.div`

`;

export const ListdWrapper = styled.div`

`;

export const Listd = styled.div`

`;
